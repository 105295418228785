import { Box, Icon, Stack, Text } from '@qcx/ui';
import { Link } from '@qcx/remix';
import { MicroArticle } from '@atreus/data';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { route } from '../route';

export const ListBullets = ({
  bullets,
  propsWrapper
}: {
  bullets: MicroArticle[];
  propsWrapper?: Record<string, string>;
}) => (
  <Stack as="ul" flow="column" gap="$3" {...propsWrapper}>
    {bullets.map((bullet, idx) => (
      <Box key={`post_${idx}`} as="li" css={{ listStyleType: 'none' }}>
        <Link
          css={{
            color: '$neutral900',
            '&:hover': {
              color: '$blue600'
            }
          }}
          title={bullet.title}
          to={route('news-show', {
            slug: bullet.slug
          })}
        >
          <Text
            as="h2"
            css={{
              d: 'flex',
              alignItems: 'start',
              color: 'currentColor',
              fontFamily: '$heading'
            }}
            variant={{
              '@initial': 'md',
              '@xl': 'xl'
            }}
            weight="semibold"
          >
            <Icon
              css={{ minMaxH: 24, color: '$blue600', mr: '$3' }}
              icon={faChevronRight}
            />
            {bullet.title}
          </Text>
        </Link>
      </Box>
    ))}
  </Stack>
);
